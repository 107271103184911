<template lang="pug">
layout-sider(
  :width="207"
  :collapsible="false"
  className="sider-dashboard")
  div(class="logo-container")
    Logo
  div(class="dashboard-menu-container")
    a-menu(
      class="dashboard-nav"
      mode="vertical")
      a-menu-item(key="dashboard")
        router-link(to="/dashboard/panel") Dashboard
      a-menu-item(key="showrooms")
        router-link(to="/dashboard/showroomsList") Showrooms
    div(class="actions-container")
      app-text(
        :customStyle="{margin: '0 auto', fontWeight:'500',textTransform:'capitalize'}"
        className="small"
        :content="username.charAt(0).toUpperCase() + username.slice(1)")
        beforeCreate
      app-text(
        v-if="$session.get('user').is_supervisor"
        :customStyle="{marginBottom: '10%'}"
        className="nano"
        content="Supervisor")
      app-text(
        className="small-error link"
        content="Cerrar Sesión"
        @click="LogOut"
        )
</template>
<script>
//Import organisms components
import LayoutSider from "@/components/organisms/LayoutSider.vue";
//import molecules
import Logo from "@/components/molecules/Logo.vue";
//Import atoms components
import AppText from "@/components/atoms/AppText.vue";
import { LOGOUT } from "@/store/actions.type";

export default {
  name: "SideBarLeft",
  components: {
    LayoutSider,
    AppText,
    Logo
  },
  beforeCreate: function() {
    this.username = this.$session.get("user").name;
  },
  methods: {
    LogOut() {
      this.$store.dispatch(LOGOUT);
      this.$session.destroy();
      this.$router.push({ name: "LoginComercial" });
    }
  }
};
</script>
<style lang="less" scoped>
.logo-container {
  height: 100px;
  background: var(--container-color) !important;
}

.dashboard-nav {
  text-align: center;
  background: inherit;
}
.actions-container {
  // margin-top: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 30%;
  width: 100%;
  text-align: center;
}

.ant-menu-item {
  font-size: 16px !important;
  font-weight: bolder;
  width: 100%;
  padding: 0;
}

.ant-menu-item:hover {
  background: inherit;
}

.router-link-active {
  border-right: 7px solid var(--primary-color) !important;
  color: var(--primary-color) !important;
}
:hover {
  color: var(--primary-color) !important;
}

.ant-menu-item:active {
  background: inherit;
}

.dashboard-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85vh;
  padding: 60px 0 30px;
}
</style>
